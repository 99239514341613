import { useState, useEffect } from "react";
import styled from "styled-components";

import { Gap } from "components/Layout";
import TopBar from "components/TopBar";
import ButtonDark from "components/ButtonV1";
import { CENTER_COLUMN_WIDTH, COLOR1, COLOR2 } from "pages/main-v2";
import TopBarV2 from "components/TopBarV2";
import { useSearchParams } from "react-router-dom";

const BackgroundContainer = styled.div``;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  z-index: 1;
  @media (max-width: 1240px) {
    padding-bottom: 20px;
  }
`;

const Content = styled.div`
  padding: 0 20px;
  @media (min-width: 1240px) {
    padding: 0;
    display: grid;
    width: ${CENTER_COLUMN_WIDTH}px;
  }

  min-height: 788px;
  height: 100vh;
  align-items: start;
  align-content: start;

  gap: 0px;
  color: white;
  transition: opacity 1s;
  margin: 0 auto;

  @media (max-width: 1240px) {
    height: auto;
    min-height: 100vh;
  }
`;

const Title = styled.div`
  font-size: 48px;
  line-height: 1.2;
  @media (min-width: 1240px) {
    font-size: 80px;
    line-height: 1.2;
  }

  font-weight: 600;
  z-index: 1;

  background-image: linear-gradient(135deg, #ffffff 0%, #d9d9d9 40%, #787368 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  text-align: center;

  span {
    font-size: inherit;
  }

  .gradient-text {
    background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);
  }
`;

const SubTitle = styled.div`
  color: #bdbdbd;
  z-index: 1;
  text-align: center;

  justify-self: center;

  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  text-align: center;

  @media (min-width: 1240px) {
    width: 820px;
    font-size: 24px;
  }
`;

const StyledSvg = styled.svg`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;

  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const LogosContainer = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  text-align: center;
  margin-top: 135px;

  @media (max-width: 1240px) {
    margin-top: 20px;
  }
`;

const Logos = styled.div`
  display: flex;
  gap: 80px;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 1240px) {
    gap: 20px;
    justify-content: center;
  }
`;

const Logo = styled.img``;

const HorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(73, 127, 243, 0) 0%,
    ${COLOR2} 25.5%,
    #ffffff 50.5%,
    ${COLOR1} 75%,
    rgba(56, 208, 218, 0) 100%
  );
`;

const HeroSectionV2 = ({ title, subTitle, buttons = [], logosTitle, logos = [] }) => {
  const [searchParams] = useSearchParams();
  const [msSincePageLoad, setMsSincePageLoad] = useState(2000);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMsSincePageLoad(msSincePageLoad + 100);
    }, 100);
    return () => clearInterval(timeoutId);
  }, [msSincePageLoad]);

  return (
    <BackgroundContainer>
      <TopBarV2 />
      <Content
        style={{
          opacity: msSincePageLoad >= 1400 ? 1 : 0,
          fill: "white",
        }}
      >
        <Gap height="160px" />
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Gap height="40px" />
        <SubTitle>{subTitle}</SubTitle>
        <Gap height="40px" />

        {searchParams?.get("showButton") === "true" && (
          <ButtonsContainer>
            <ButtonDark
              style={{ padding: "0px 40px" }}
              isPrimary
              onClick={() => {
                window.open("https://flow.boltzbit.com/chat", "_blank");
              }}
            >
              {buttons?.[0].text}
            </ButtonDark>
          </ButtonsContainer>
        )}

        <LogosContainer>
          <SubTitle>{logosTitle}</SubTitle>
          <Gap height="20px" />
          <Logos>
            {logos.map((logo, i) => {
              if (logo?.url) {
                return (
                  <a href={logo?.url} target="_blank" rel="noreferrer">
                    <Logo src={logo?.src} key={i} style={{ height: logo?.height || 30 }} />
                  </a>
                );
              }

              return <Logo src={logo?.src} key={i} style={{ height: logo?.height || 30 }} />;
            })}
          </Logos>
          <Gap height="28px" />
          <HorizontalLine />
          <Gap height="10px" />
        </LogosContainer>
      </Content>
    </BackgroundContainer>
  );
};

export default HeroSectionV2;
