import { getFlowDatabaseTemplates } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

export const CENTER_COLUMN_WIDTH = 1200;
export const COLOR1 = "#38D0DA";
export const COLOR2 = "#497FF3";
export const BORDER_TOP_GREY = "#9b9b9b";

export const GRADIENT_TEXT = `
background: linear-gradient(88.57deg, ${COLOR2} 17.21%, ${COLOR1} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

export const GRADIENT_TEXT_FLIPPED = `
background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

const SECTIONS = [
  {
    type: "hero-v2",
    title: "<span class='gradient-text'>Boltzflow</span><br/>The GenAI you can trust",
    subTitle: "Search, chat, write, analyse, code, plan for your everyday work and life.",
    buttons: [{ text: "Get started for free!" }],
    logosTitle: "Trusted by",
    logos: [
      { src: "/images/aws-logo-white.png" },
      { src: "/images/liquidnet-logo-white.png", url: "https://www.liquidnet.com/" },
      { src: "/images/google-cloud-logo.png" },
      { src: "/images/tpicap-logo.png", height: 60, url: "https://tpicap.com/tpicap/" },
    ],
  },
  {
    type: "journey-v2",
    title: "<span class='gradient-text'>The AI solution that just works.</span>",
    subTitle: "Everything you need, straight from your chatbox.",
    placeholder: "What can we do for you?",
    bottomButtons: [
      { text: "Write my report" },
      { text: "Create a table" },
      { text: "Respond to emails" },
      { text: "Build a database" },
    ],
    imagesSections: [
      {
        imageSrc: "/images/journey-images/aggregate.png",
        title: "Search",
        text: "Pull your data from <span style='font-weight: 500; font-size: inherit;'>emails, websites, external feeds, PDFs, excels</span> and images.",
      },
      {
        imageSrc: "/images/journey-images/analyse.png",
        title: "Summarise",
        text: "Your private model understands your requests and retrieves your data.",
      },
      {
        imageSrc: "/images/journey-images/automate.png",
        title: "Execute",
        // text: "Use your predictions, <span style='font-weight: 500; font-size: inherit;'>forecasts and insights</span> to grow revenue faster.",
        text: "Use our predictions, forecasts and insights to grow revenue faster.",
      },
    ],
  },
  {
    type: "word-cloud-v2",
    title: "Private GenAI",
    subTitle: "<span class='gradient-text'>customised for every business</span>",
    cloudLines: [
      ["Accounting", "Sales", "Marketing", "HR"],
      ["Legal", "Education", "Recruitment", "Real Estate"],
      ["Events", "Health", "Software", "Financial", "Compliance"],
      ["Careers", "Travel", "Creative Writing", "Other"],
    ],
    highlights: [
      [1, 0, 1, 0],
      [0, 1, 0, 1],
      [0, 1, 0, 1, 0],
      [0, 1, 0, 1],
    ],
  },
  {
    type: "message-v2",
    leftTitle: "Groundbreaking Research for the worlds fastest LLM",
    text: "Our research in Boltzman machines generated the most powerful LLM, <span style='font-weight: 600; font-size: inherit;'>surpassing all GPTs to date.</span>",
    isLinkVisible: true,
  },
  {
    type: "three-cards-v2",
    title: "We customise <br /><span style='color: #497FF3; font-size: inherit;'>GenAI for enterprise</span>",
    columns: [
      {
        title: "Keep your data as you wish",
        text: "Our tailored Gen-AI models are deployed & managed by your own IT. Host your solution on public, private cloud or on-premesis for maximum control.",
      },
      {
        title: "Lay a foundation you can build upon",
        text: "This is just the beginning. Build AI Engines to solve specific problems and automate your solutions.",
      },
      {
        title: "Train your own custom models for best results",
        text: "Every person is unique. By using your own data, you can boost the accuracy of your models with our user friendly fine-tuning.",
      },
    ],
  },
  {
    type: "banner-v2",
    heading: "Stay ahead of the curve<br /> with your own GenAI.",
    text: "Talk to us",
  },
  {
    type: "footer-v2",
  },
];

const DesktopContainer = styled.div`
  background: #151617;

  @media (min-width: 1240px) {
    background-position: 0 -0px;
    background: linear-gradient(
      180deg,
      #151617 0%,
      #0f1219 12.5%,
      #2d303a 28%,
      #1b1e26 40.5%,
      #2d303a 54.5%,
      #1b1f26 74.5%,
      #2d303a 86.5%,
      #151617 100%
    );
  }
`;

const Container = styled.div``;

const MainPageV2 = () => {
  const [searchParams] = useSearchParams();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  const token = searchParams.get("token");

  // if (token !== "bzabc") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} isFooterHidden />
      </DesktopContainer>
    </Container>
  );
};

export default MainPageV2;
