import styled from "styled-components";
import { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";

import Button from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import { BoltzflowLogo, BoltzhubLogo } from "components/ui/Icons";

const TopContainer = styled.div`
  position: fixed;
  top: 0;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(3, auto);
  gap: 22px;
  padding: 20px 20px;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease-in-out;
  ${props => props.scrollY > 200 && "background-color: rgba(0, 0, 0, 0.95);"}
`;

const Video = styled.video`
  height: 100vh;
  object-fit: cover;

  @media (min-width: ${props => props.theme.breakpoint.lg}) {
    width: 100%;
    height: auto;
    max-height: 100vh;
    object-fit: cover;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;

  background: linear-gradient(
    10deg,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.8) 30%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0)
  );
  /* background: radial-gradient(
    ellipse at 14% 80%,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.6) 20%,
    rgba(0, 0, 0, 0.05) 40%,
    rgba(0, 0, 0, 0.05)
  ); */

  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
`;

const ImgAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: ${props => props.theme.color.furthest};
`;

const WhiteButton = styled(Button)`
  padding: 4px 16px;
  /* background: ${props => props.theme.color.furthest};
  :hover {
    opacity: 0.8;
  } */
  font-size: 14px;

  bottom: 0;
  right: 0;
`;

const NavLink = styled.a`
  font-size: 16px;
  text-decoration: none;
  color: ${props => props.theme.color.furthest};
  :hover {
    opacity: 0.8;
  }
`;

const MessagesAndButtons = styled.div`
  position: absolute;
  bottom: 60px;
  left: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
`;

const Message = styled.div`
  font-weight: 500;
  line-height: 1.2;
  color: ${props => props.theme.color.furthest};
  font-size: 42px;
`;

const MessagePrimary = styled(Message)`
  color: ${props => props.theme.color.primary};
  background-image: linear-gradient(
    to right,
    ${props => props.theme.color.secondary} 40%,
    ${props => props.theme.color.primary}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;

const BlueMessage = styled(Message)`
  /* color: ${props => props.theme.color.primary}; */
  color: #1e8dfb;
`;

const AnimatedSpan = styled.span`
  display: inline-block;
  color: ${props => props.theme.color.furthest};
  * {
    font-size: 42px;
  }
`;

// const FLOW_BASE_URL = "https://flow.boltzbit.com";
const FLOW_BASE_URL = "http://localhost:5000";

const LOGOS = {
  flow: <BoltzflowLogo style={{ fill: "white", height: "28px" }} />,
  hub: <BoltzhubLogo style={{ fill: "white", height: "28px" }} />,
};

const TemplateLink = styled.a`
  display: block;
  border: 1px solid #aaaaaa;
  color: ${props => props.theme.color.furthest};
  text-decoration: none;
  background-color: ${props => props.theme.color.closest};
  width: 500px;
  padding: 6px 12px;
  :hover {
    background: linear-gradient(
      to right,
      ${props => props.theme.color.primary},
      ${props => props.theme.color.secondary}
    );
  }
`;

const VideoSection = ({
  messageLine1,
  messageLine2,
  messageLine2Blue,
  typewriterMessages = [],
  logo = "flow",
  videoSrc,
  templates = [],
}) => {
  const navigate = useNavigate();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const updateScrollY = () => setScrollY(window.scrollY);
    document.addEventListener("scroll", updateScrollY);

    return () => document.removeEventListener("scroll", updateScrollY);
  }, []);

  useEffect(() => {
    if (!typewriterMessages?.length) {
      return;
    }

    new Typewriter("#typewriter", {
      strings: ["what", "the"],
      autoStart: true,
      loop: true,
    });
  }, [typewriterMessages?.length]);

  console.log({ templates });

  return (
    <Container>
      <TopContainer scrollY={scrollY}>
        <ImgAndTitle>
          {LOGOS[logo]}
          <Title>{logo === "flow" ? "Boltzflow" : "Boltzhub"}</Title>
        </ImgAndTitle>
        <NavLink href="https://boltzbit.com/" target="_blank">
          Boltzbit
        </NavLink>
        {logo === "flow" ? (
          <NavLink href="/hub?token=e8f44f7a-bb23-4360-b19b-56739f5c1b9e" target="_blank">
            Boltzhub
          </NavLink>
        ) : (
          <NavLink href="/flow?token=e8f44f7a-bb23-4360-b19b-56739f5c1b9e" target="_blank">
            Boltzflow
          </NavLink>
        )}
        {/* <NavLink href="https://boltzbit.com/about/team/" target="_blank">
          About
        </NavLink>
        <NavLink href="https://boltzbit.com/news" target="_blank">
          Blog
        </NavLink>
        <NavLink href={logo === "flow" ? "https://boltzflow.com/" : "https://boltzhub.com/"} target="_blank">
          Login
        </NavLink> */}
        <WhiteButton
          value="Join Waitlist"
          onClick={() => {
            navigate("contact");
          }}
        />
      </TopContainer>

      <VideoOverlay />
      <Video loop autoPlay muted>
        <source src={videoSrc} type="video/mp4"></source>
      </Video>

      <MessagesAndButtons>
        <Message>
          {templates?.length > 0 && (
            <>
              Start creating
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                {templates?.map(template => (
                  <TemplateLink
                    key={template?._id}
                    href={`${FLOW_BASE_URL}/flow-dashboard-templates/${template?._id}/resolve`}
                    target="_blank"
                  >
                    {template?.prompt}
                  </TemplateLink>
                ))}
              </div>
            </>
          )}
          {!templates?.length && (
            <>
              {messageLine1}&nbsp;
              <AnimatedSpan style={{ color: logo === "flow" ? "#0191ff" : "#e17a4a" }}>
                <Typewriter
                  options={{
                    strings: typewriterMessages,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </AnimatedSpan>
            </>
          )}
        </Message>
        <MessagePrimary>{messageLine2}</MessagePrimary>
        <BlueMessage>{messageLine2Blue}</BlueMessage>
        <WhiteButton
          variant="highlighted"
          value="Join Waitlist"
          onClick={() => {
            navigate("contact");
          }}
        />
      </MessagesAndButtons>
    </Container>
  );
};

export default VideoSection;
